export default function(date: any): string {
    date = new Date(date);

    if (isNaN(date.getTime())) {
        return '';
    }

    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}
