import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

const Text: FC<IProps> = (props) => {
    return (
        <div className={props.className}>
            Все права на материалы, размещенные на сайте www.ruscyberleague.ru (далее – Сайт) и являющиеся объектами исключительных прав, в том числе на зарегистрированные товарные знаки и символику «РКЛ»/«RCL» (далее – Товарные знаки), а также на фото- и видеоизображения, статьи и т.п., правообладателем которых является ООО «Киберспортивная лига», охраняются в соответствии с законодательством РФ. Размещение и/или использование Товарных знаков и иных объектов интеллектуальной собственности, правообладателем которых является ООО «Киберспортивная лига», без согласования с ООО «Киберспортивная лига» рассматриваются и преследуются как нарушения интеллектуальных прав ООО «Киберспортивная лига» в соответствии с законодательством РФ. Запрещается автоматизированное извлечение информации Сайта любыми сервисами без официального разрешения ООО «Киберспортивная лига». Цитирование с Сайта и сателлитных проектов официальных сообщений Российской киберспортивной лиги допускается только при наличии прямой ссылки на сайт www.ruscyberleague.ru.
        </div>
    );
};

export default Text;
